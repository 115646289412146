<template>
    <ODialog v-model:show="showDialog" disableResize size="lg" :title='$t("Give Feedback to Omega 365")' :disableMovable="false" disableFade :target="targetEl">
        <div class="o365-dialog-body d-flex flex-column">
            <div v-if="!submitted" class="d-flex flex-column flex-grow-1 w-100">
                <div class="m-2">
                    <div class="form-check">
                        <input class="form-check-input" type="radio" :value="dsDatabaseSetup.current.BugProcess_ID" name="af-navbar-process-radio" v-model="selectedProcess" id="af-navbar-bug" checked>
                        <label class="form-check-label" for="af-navbar-bug">
                            <span><b>{{ $t("Bug Report") }}</b> {{ $t("Something went wrong") }}</span>
                        </label>
                    </div>
                    <div class="form-check" v-if="dsDatabaseSetup.current.MissingFeatureProcess_ID">
                        <input class="form-check-input" type="radio" :value="dsDatabaseSetup.current.MissingFeatureProcess_ID" name="af-navbar-process-radio" v-model="selectedProcess" id="af-navbar-missing-feature">
                        <label class="form-check-label" for="af-navbar-missing-feature">
                            <span><b>{{ $t("Missing Feature") }}</b> {{ $t("Feature is required to get the job done") }}</span>
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" :value="dsDatabaseSetup.current.FeatureProcess_ID" name="af-navbar-process-radio" v-model="selectedProcess" id="af-navbar-feature">
                        <label class="form-check-label" for="af-navbar-feature">
                            <span><b>{{ $t("Feature Request") }}</b> {{ $t("Help us improve Omega 365") }}</span>
                        </label>
                    </div>
                </div>
                <div>
                    <label>{{ $t("Product") }}</label>
                    <ODataLookup 
                        disableRecent
                        class="form-control form-control-sm"
                        :bind="sel => { selectedProduct.ID = sel.OrgUnit_ID; selectedProduct.Name = sel.Name; }"
                        :data-object="dsProducts"  
                        :value="selectedProduct.Name"
                    > 
                        <OColumn field="Name" width="350"></OColumn>
                    </ODataLookup> 
                </div>
                <div class="mt-1">
                    <label>{{ $t("Title") }}</label>
                    <input class="form-control form-control-sm" v-model="title"/>
                </div>
                <div class="mt-1">
                    <label>{{ $t("Description") }}</label>
                    <OTextArea 
                        v-model="description"
                        class="form-control form-control-sm"
                        autoGrow
                        rows="4"
                        noResize/>
                </div>
                <div class="mt-auto">
                    <div class="my-3 border"></div>
                    <div class="d-flex" >
                        <div class="ms-auto">
                            <button class="btn btn-sm btn-primary ms-1" @click="submitWorkflow">
                                <span class="me-1">{{ $t("Submit") }}</span>
                                <div class="spinner-border spinner-border-sm" role="status" v-if="submitting">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="d-flex justify-content-center" style="height: 100px;">
                <div>
                    <div class="text-center">
                        <i class="bi bi-check-circle text-success" style="font-size: 40px;"></i>
                    </div>
                    <div class="text-center fw-bold">
                        {{ $t("Workflow submitted") }}
                    </div>
                </div>
            </div>
        </div>
    </ODialog>
</template>
  
<script setup>
import { ref, onMounted, defineExpose, inject } from 'vue';
import { ODialog, OTextArea } from 'o365-ui-components';
import { getOrCreateDataObject } from 'o365-dataobject';
import { ODataLookup } from 'o365-datalookup';
import { OColumn } from 'o365-datagrid';
import { API } from 'o365-modules';
 
const targetEl = ref();
const showDialog = ref(false);
const selectedProduct = ref({
    ID: null,
    Name: null
});
const title = ref(null);
const description = ref(null);
const selectedProcess = ref(null);
const submitted = ref(false);

const setOnOpen = inject('set-on-open', () => {});
setOnOpen(() => {
    showDialog.value = true;
});

const dsProducts = getOrCreateDataObject({
    "id": `dsProducts-Navbar`,
    "isStaticId": true,
    "viewName": "sviw_O365_Products",
    "distinctRows": false,
    "uniqueTable": null,
    "allowUpdate": false,
    "allowInsert": false,
    "allowDelete": false,
    "appendData": false,
    "selectFirstRowOnLoad": true,
    "fields": [
        {
            "name": "ID",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "Name",
            "sortOrder": 1,
            "sortDirection": "asc",
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "OrgUnit_ID",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
    ],
    "masterDataObject_ID": null,
    "masterDetailDefinition": [],
    "clientSideHandler": false,
    "maxRecords": 100,
    "dynamicLoading": false,
    "whereClause": null,
    "loadRecents": false,
    "filterString": null,
    "disableAutoLoad": false,
    "optimisticLocking": false
});

const dsDatabaseSetup = getOrCreateDataObject({
    "id": `dsDatabaseSetup-Navbar`,
    "isStaticId": true,
    "viewName": "stbv_Database_Setup",
    "distinctRows": false,
    "uniqueTable": null,
    "allowUpdate": false,
    "allowInsert": false,
    "allowDelete": false,
    "appendData": false,
    "selectFirstRowOnLoad": true,
    "fields": [
        {
            "name": "BugProcess_ID",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "FeatureProcess_ID",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
        {
            "name": "MissingFeatureProcess_ID",
            "sortOrder": null,
            "sortDirection": null,
            "groupByOrder": null,
            "groupByAggregate": null,
            "type": null,
            "aggregate": null
        },
    ],
    "masterDataObject_ID": null,
    "masterDetailDefinition": [],
    "clientSideHandler": false,
    "maxRecords": 1,
    "dynamicLoading": false,
    "whereClause": null,
    "filterString": null,
    "disableAutoLoad": false,
    "optimisticLocking": false
})

const submitting = ref(false);
async function submitWorkflow(){
    var response = await API.requestPost("/api/scope/submitfeedback", JSON.stringify({
        ProcessID: selectedProcess.value ?? dsDatabaseSetup.current.BugProcess_ID,
        OrgUnitID: selectedProduct.value.ID,
        Title: title.value,
        Description: description.value
    }));
    if(response.status == 200){
        submitted.value = true;
    }
} 

function show(){
    showDialog.value = true;
} 

onMounted(() => {
    setTimeout(async function(){
        showDialog.value = true;
        dsDatabaseSetup.load();
    }, 100);
})

defineExpose({ show });

</script>